import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./components/login/login.component";
import { NoAccessComponent } from "./components/no-access/no-access.component";
import { AccessGuard } from "@appcore/guards/access.guard";

const routes: Routes = [
    {path: '', pathMatch: 'full', redirectTo: 'login'},
    {path: 'login', component: LoginComponent},
    {path: 'noaccess', component: NoAccessComponent},
    {
        path: 'open-position',
        loadChildren: () => import('./modules/open-position/open-position.module').then(m => m.OpenPositionModule)
    },
    {
        path: 'dashboard',
        canActivate: [AccessGuard],

        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: {
            access: ['user', 'org', 'admin']
        }
    },
    {
        path: 'ombord-insight/:shippingCompanyId',
        loadChildren: () =>
            import('./modules/ombord-insight/ombord-insight.module').then(m => m.OmbordInsightModule),
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
